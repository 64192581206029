import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/workflow",
        name: "workflow",
        component: Layout,
        redirect: '/workflow/index',
        meta: {
            title: "处理中心",
            icon: "order",
        },
        children: [
            {
                path: "consumer",
                name: "workflow-consumer",
                component: () => import("@/views/workflow/consumer.vue"),
                meta: {
                    title: "待处理工单",
                    icon: "order",
                    permission: "list_workflow_bucket"
                },
            },
            {
                path: "producer",
                name: "workflow-producer",
                component: () => import("@/views/workflow/producer.vue"),
                meta: {
                    title: "我创建的工单",
                    icon: "workflow_bucket",
                    permission: "list_workflow_bucket"
                },
            },
            {
                path: "index",
                name: "workflow-index",
                component: () => import("@/views/workflow/index.vue"),
                meta: {
                    title: "全部工单",
                    icon: "workflow_type",
                    permission: "list_workflow_bucket"
                },
            },
            {
                path: "type",
                name: "workflow-type",
                component: () => import("@/views/workflow/type.vue"),
                meta: {
                    title: "类型配置",
                    icon: "model",
                    permission: "manage_workflow_type"
                },
            },
            // {
            //     path: "progress",
            //     name: "workflow-progress",
            //     component: () => import("@/views/workflow/progress.vue"),
            //     meta: {
            //         title: "进度配置",
            //         icon: "workflow_progress",
            //         permission: "manage_workflow_progress"
            //     },
            // },
            {
                path: "create",
                name: "create-workflow",
                component: () => import("@/views/workflow/workflow-form.vue"),
                meta: {
                    title: "创建工单",
                    permission: "create_workflow_bucket",
                    activeMenu: "/workflow/index",
                },
                hidden: true,
            },
            {
                path: "edit/:id(\\d+)",
                name: "edit-workflow",
                component: () => import("@/views/workflow/workflow-form.vue"),
                meta: {
                    title: "编辑工单",
                    permission: "update_workflow_bucket",
                    activeMenu: "/workflow/index",
                },
                hidden: true,
            },
            {
                path: "detail/:id(\\d+)",
                name: "workflow-detail",
                component: () => import("@/views/workflow/workflow-detail.vue"),
                meta: {
                    title: "工单详情",
                    permission: "get_workflow_bucket",
                    activeMenu: "/workflow/index",
                },
                hidden: true,
            },
            {
                path: "question",
                name: "tool-question",
                component: () => import("@/views/tool/question.vue"),
                meta: {
                    title: "问题汇总",
                    icon: "question",
                    keepAlive: true,
                    permission: "manage_tool_question",
                },
            },
        ]
    }
];
